<template>
    <div>
        <div v-if="$store.state.UserName !== $store.state.HTM0" class="MT2" style="font-weight: bolder;">
            <MyDivider ShowStr="尊享充值"></MyDivider>
            <div class="XLR YC MP4">
                <div class="PA2 BD_Radius1 BGDeepGreen TW T7" @click="WXCZ(20)"> 20元</div>
                <div class="PA2 BD_Radius1 BGDeepGreen TW T7" @click="WXCZ(60)"> 60元</div>
                <div class="PA2 BD_Radius1 BGDeepGreen TW T7" @click="WXCZ(100)">100元</div>
            </div>
            <div class="XLR YC MP4">
                <div class="PA2 BD_Radius1 BGDeepGreen TW T7" @click="WXCZ(200)">200元</div>
                <div class="PA2 BD_Radius1 BGDeepGreen TW T7" @click="WXCZ(600)">600元</div>
                <div class="PA2 BD_Radius1 BGDeepGreen TW T7" @click="WXCZ(980)">980元</div>
            </div>
        </div>
        <div v-if="!FromFYWed" class="BGGray MP4">
            <div class="XL YC BottGray MLR2">
                泛约网中昵称:
                <input type="text" class="MP4 W50" @focus="fywUserName=null" v-model="fywUserName"/>
            </div>
            <div class="XL YC BottGray MLR2">
                泛约网中密码：<input type="password" maxlength="18" @focus="fywPassword=null" placeholder="6-18位数字&字母组合" class="MP4 W50" v-model="fywPassword"/>
            </div>
            <div class="XLR YC">
                <div class="XL YC BottGray MLR2">
                    泛约币余额：
                    <input class="W12" type="number" @focus="fywZXn=null" v-model="fywZXn"/>
                </div>
                <div class="AN ANGreen" @click.once="FYBtoZX">导入</div>
                <div class="AN ANBlue" @click.once="FYBtoZXNoMore">放弃导入</div>
            </div>
        </div>
        <div v-if="$store.state.UserName === $store.state.HTM0" class="BottGray">
            <div class="BGLightGray PA3 MT2">
                <div class="XLR YC MTB2">
                    尊享转让☞
                    <div class="AN ANBlue" @click="MoveZX(aUserName,ZXn)">确认</div>
                </div>
                <div class="XLR YC MTB2 TF">
                    <div>向：<input type="text" class="W26 MT2" v-model="aUserName" placeholder="泛约用户名"/></div>
                    <div>转让尊享：<input type="number" class="W12 MT2" v-model.number="ZXn" @focus="ZXn=null"/>个</div>
                </div>
                <hr>
                <div class="XLR YC MT2">
                    {{aUserName}}的收支明细:
                    <div></div>
                    <div class="AN ANBlue" @click="OpenHisZX=true">打开</div>
                    <div class="AN ANGreen" @click="OpenHisZX=false">关闭</div>
                </div>
            </div>
        </div>
        <div v-else class="TC BGO PA2 XLR YC">
            <div></div>
            1元=10个尊享
            <div class="TC LH120" style="font-weight: bolder"> {{'现有：'+$store.state.ZX+'个'}}</div>
            <div></div>
        </div>
        <div v-if="$store.state.UserName === $store.state.HTM0" class="BGLightGray BD_Radius1 PA2">
            <div class="BottGray XLR YC H3 MTB2 BGWhite" @click="OpenMyZXAccount=!OpenMyZXAccount">&nbsp;
                <div class="T4 TG ML2">我的尊享收支明细</div>
                <div></div>
                <div v-if="OpenMyZXAccount">
                    <el-icon><ArrowUp/></el-icon>
                </div>
                <div v-else>
                    <el-icon><ArrowDown/></el-icon>
                </div>
            </div>
        </div>
        <div v-if="OpenHisZX">
            <MyDivider :ShowStr="aUserName+'/'+Year+'年度'"></MyDivider>
            <ZXAccounts :UserName="aUserName"></ZXAccounts>
        </div>
        <div v-if="OpenMyZXAccount">
            <MyDivider ShowStr="我的收支明细"></MyDivider>
            <ZXAccounts :UserName="$store.state.UserName"></ZXAccounts>
        </div>
        <div v-if="$store.state.UserName !== $store.state.HTM0" class="BGLightGray BD_Radius1 PA3 MT2">
            <MyDivider ShowStr="退币提现"></MyDivider>
            <div class="XLR YC MTB2">
                <div class="TF">
                    尊享个数：<input type="number" class="W12" v-model.number="NowOutZXn" @focus="NowOutZXn=null"
                                style="height: 2rem"/>
                </div>
                <div class="PA2 BD_Radius1 BGBlue TW" @click="Submit()">提交</div>
            </div>
            备注：若要提现，您需要完成实名认证，且尊享存量应至少为10个。
        </div>
        <br>
        
        <div v-if="1===2" class="BottGray XLR YC H3 MTB2" @click="OpenZXrules=true">&nbsp;
            <div class="T4 TG ML2">“平台内应用”尊享流通规则</div>
            <div></div>
            <el-icon><ArrowRight/></el-icon>
        </div>
        <div v-if="$store.state.UserName === $store.state.HTM0">
            <MyDivider ShowStr="平台内流通尊享"></MyDivider>
            <div class="XLR YC PA3">
                <div>注册用户数：{{SumUsers}}</div>
                总量：{{SumZX}}
            </div>
            <table>
                <thead>
                <tr class="TF ">
                    <th>序号</th>
                    <th>
                        <div class="LH200">泛约用户名</div>
                    </th>
                    <th>微信昵称</th>
                    <th>尊享1</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(UZX,index) in UserZXs" :key="index">
                    <td>{{index+1}}</td>
                    <td class="LH150">{{UZX.UserName}}</td>
                    <td>{{UZX.WXNickName}}</td>
                    <td>{{UZX.ZX}}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <div v-if="1===2" class="MTB2">
            <div class="MLR4 MT2" style="background: 0 0 / 100% 100% url('../../../static/img/MyFrame.png') no-repeat;">
                <br><br><br>
                <div class="XLR YC">&nbsp;
                    <div class="AlignC W26">
                        <div class="BottGray T3">&nbsp;注册用户&nbsp;</div>
                        <div class="TE T8 MT2">{{SumUsers}}</div>
                    </div>&nbsp;
                    <div class="AlignC W26">
                        <div class="BottGray T3">&nbsp;尊享总量&nbsp;</div>
                        <div class="TE T8 MT2">{{SumZX}}</div>
                    </div>&nbsp;
                    <div class="AlignC W26" @click="OpenMyZXAccount=true">
                        <div class="BottGray T3">&nbsp;我的尊享&nbsp;</div>
                        <div class="TE T8 MT2">{{$store.state.ZX}}</div>
                    </div>&nbsp;
                </div>
                <br><br>
            </div>
        </div>
        
        <PopTitle v-if="OpenZXrules" Title="“平台内应用”尊享流通规则" @FromPopUp="OpenZXrules=false">
            <ZXrules></ZXrules>
        </PopTitle>
    </div>
</template>

<script>
    import {
        TransferZX,
        myMongoDBPost, myWXpost, weixnVersion, GetWxInterface, ReGetZX, StampToTimeStr, MyUrl, SendWXMsg, SendWXPicMsg, KeepZX, MyRandomCode
    } from '@/components/SharedVues/Shared0.js'
    import ShowUpdatePVT from "../MyPVTs/ShowUpdatePVT";

    import ZXAccounts from "./ZXAccounts";
    import PopTitle from "../MyPopWin/PopTitle";
    import ZXrules from "./ZXrules";
    import ShowUpdateP from "../MyPVTs/ShowUpdateP";

    import MyDivider from "../SharedVues/MyDivider";

    export default {//尊享管理
        name: "ZXGL",
        components: {MyDivider, ShowUpdateP, ZXrules, PopTitle, ZXAccounts, ShowUpdatePVT},
        data() {
            return {
                SumUsers: 0,
                SumZX: 0,
                OpenMyZXAccount: this.$store.state.UserName !== this.$store.state.HTM0,
                OpenZXrules: false,
                aUserName: '',
                ZXn: 0,
                NowOutZXn: 0,
                UserZXs: [],
                OpenHisZX: false,
                fywUserName: '',
                fywPassword: '',
                fywZXn: 0,
                FromFYWed: false,
                CurrUserBools: []
            }
        },
        mounted() {
            let that = this;
            document.getElementById('TitleId').innerText = '尊享管理中心';
            this.GetUserZXs();
            ReGetZX(this, function (ZX) {
            });
            myMongoDBPost("GetDocs/fyuser0", {UserName: this.$store.state.UserName}, function (Docs1) {
                that.CurrUserBools = Docs1[0].UserBools;
                that.FromFYWed = that.CurrUserBools.indexOf('泛约币已导入') >= 0 || that.CurrUserBools.indexOf('放弃导入泛约币') >= 0;
            });
        },
        beforeUnmount() {
            let that = this;
            myMongoDBPost("GetDocs/fyuser0", {UserName: this.$store.state.UserName}, function (Docs1) {
                that.$store.commit('ChangeZX', Docs1[0].ZX);
            })
        },
        methods: {
            WXCZ(WXCZ0) {
                let that = this;
                that.OpenMyZXAccount = false;
                if (weixnVersion() === -1) alert("无效操作：微信支付必须通过微信公众号进行操作!"); else {
                    if (parseInt(weixnVersion().split(".")[0]) < 5) return alert("5.0以下微信版本不支持微信支付功能!");
                    if (that.$store.state.UserName === "游客") return alert("游客用户不能进行微信支付!");
                    if (WXCZ0 < 0.01) return alert("本支付金额不被支持!");
                    myMongoDBPost("GetDocs/fyuser0", {UserName: this.$store.state.UserName}, function (Docs) {
                        let openid = Docs[0].WXId;
                        if (openid === 'Error!') alert("获取用户微信id号失败!"); else {
                            let aObj = {
                                out_trade_no: 'CZ' + (new Date()).getTime() + that.$store.state.UserName.length,
                                amount: parseInt(WXCZ0 * 100),// UrlObj.amount,//订单金额,integer,单位为“分”。
                                openid: that.$store.state.WXId,//收款用户OpenID
                                Timestamp: Math.floor((new Date().getTime()) / 1000),
                                nonce_str: MyRandomCode(30)
                            };
                            myWXpost("WeixinCZ1", aObj, function (ObjData) {
                                function onBridgeReady() {
                                    WeixinJSBridge.invoke('getBrandWCPayRequest', ObjData, function (res) {
                                        myMongoDBPost("GetDocs/WXZFs", {out_trade_no: aObj.out_trade_no}, function (Docs1) {
                                            if (Docs1[0] && Docs1[0].payer.openid === that.$store.state.WXId && Docs1[0].amount.total === aObj.amount)
                                                KeepZX(that, that.$store.state.UserName, '收', WXCZ0 * 10, '尊享充值', function () {
                                                    that.OpenMyZXAccount = true;
                                                }); else myMongoDBPost("GetDocs/WXZFs", {out_trade_no: aObj.out_trade_no}, function (Docs2) {
                                                if (Docs2[0] && Docs2[0].payer.openid === that.$store.state.WXId && Docs2[0].amount.total === aObj.amount)
                                                    KeepZX(that, that.$store.state.UserName, '收', WXCZ0 * 10, '尊享充值', function () {
                                                        that.OpenMyZXAccount = true;
                                                    });
                                                else alert('充值失败！');
                                            });
                                        });
                                    });
                                }

                                if (typeof WeixinJSBridge == "undefined") {
                                    if (document.addEventListener) {
                                        document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
                                    } else if (document.attachEvent) {
                                        document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
                                        document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
                                    }
                                } else {
                                    onBridgeReady();
                                }
                            });
                        }
                    });
                }
            },
            GetUserZXs() {
                let that = this;
                that.UserZXs = [];
                myMongoDBPost("GetDocs/fyuser0", {}, function (Docs1) {
                    that.UsersN = Docs1.length;
                    myMongoDBPost("GetAggregate/fyuser0", {
                        $group: {
                            _id: "",
                            SumUsers: {$sum: 1},
                            SumZX: {$sum: "$ZX"},
                        }
                    }, function (Docs2) {
                        that.SumUsers = Docs2[0].SumUsers;
                        that.SumZX = Docs2[0].SumZX;
                    });

                    for (let i = 0; i < Docs1.length; i++)
                        if (Docs1[i].ZX > 0) that.UserZXs.push({
                            Id: Docs1[i]._id,
                            UserName: Docs1[i].UserName,
                            WXNickName: Docs1[i].WXNickName,
                            ZX: Docs1[i].ZX
                        });

                });
            },
            SendWXPicMsg() {
                SendWXPicMsg(this.$store.state.UserName, '收款码');
            },
            StampToTimeStr(Stamp) {
                return StampToTimeStr(Stamp)
            },
            MoveZX(aUserName, ZXn) {
                let that = this;
                if (ZXn > 0) myMongoDBPost("GetDocs/fyuser0", {UserName: aUserName}, function (Docs1) {
                        if (Docs1.length === 0) alert('转让失败：泛约用户名[' + aUserName + ']不存在!'); else {
                            let Time = StampToTimeStr((new Date()).getTime()).split(" ");
                            let Url = MyUrl('LandTo=ZXGL');
                            let fyM0Url = '[ ' + '<a href="' + Url + '">查看一下</a>' + ' ]';
                            let aTip = '成功补充' + ZXn + '个尊享!';

                            TransferZX(that, that.$store.state.UserName, aUserName, ZXn, function (StrZX) {
                                    if (StrZX === "尊享转移成功!") myMongoDBPost("UpdateDoc/fyuser0", {
                                        UPTJ: {UserName: aUserName},
                                        UPObj: {XYZ: {Tip: aTip, Time1: Time[0], Time2: Time[1]}}
                                    }, function () {
                                        alert('尊享补充成功：已通知“' + aUserName + '”');
                                        let TemplateObj = {
                                            TemplateIndex: 1,
                                            Data: {
                                                thing15: {value: '尊享补充'},
                                                thing5: {value: that.$store.state.UserName},
                                                character_string34: {value: ZXn},
                                                thing48: {value: '平台管理员'}
                                            }
                                        }
                                        SendWXMsg(aUserName, aTip + fyM0Url, TemplateObj
                                        )
                                        ;
                                    })
                                }
                            );
                            that.aUserName = '';
                            that.ZXn = 0;
                        }
                    }
                )
                ;
                else
                    alert('失败：请输入尊享数量!');
            },
            Submit() {
                let that = this;
                that.OpenMyZXAccount = false;
                if (this.$store.state.UserName === '游客') alert("游客操作无效");
                else if (this.NowOutZXn === null || this.NowOutZXn < 3) alert("请输入有效尊享数量！");
                else if (that.$store.state.ZX < this.NowOutZXn) alert('您的尊享数量不足：无法提现!');
                else if (that.$store.state.WXId === '') alert('不是合法的微信用户，无法提现!');
                else if (that.$store.state.RealName === '') alert('未完成实名认证的用户，无法提现!');
                else myMongoDBPost("GetDocs/Cards", {CUserName: this.$store.state.UserName, Bool: '待刷卡'}, function (Docs1) {
                        if (Docs1.length > 0) {
                            alert('您在“' + Docs1[0].SiteShortName + '”有个“待刷卡”, 须先取消或刷卡后，才能进行兑现操作！')
                        } else {
                            let aObj = {
                                UserName: that.$store.state.UserName,
                                openid: that.$store.state.WXId,
                                user_name: that.$store.state.RealName,
                                out_bill_no: 'TX' + (new Date()).getTime(),
                                Timestamp: Math.floor((new Date().getTime()) / 1000),
                                nonce_str: MyRandomCode(30),
                                transfer_amount: that.NowOutZXn * 10,
                                transfer_remark: '退币提现'
                            };
                            myWXpost("WeixinTransfer1", aObj, function (Data) {
                                if (Data.state === 'ACCEPTED') alert('由于您提现金额较大，须先联系平台客服。然后重试！');
                                else if (Data.state === 'SYSTEM_ERROR') alert('由于未知原因，转账失败。请再来一次！');
                                else if (Data.state === 'WAIT_USER_CONFIRM')
                                    wx.ready(function () {
                                        wx.checkJsApi({
                                            jsApiList: ['requestMerchantTransfer'],
                                            success: function (res) {
                                                if (res.checkResult['requestMerchantTransfer']) {
                                                    WeixinJSBridge.invoke('requestMerchantTransfer', {
                                                            mchId: '1496627572',
                                                            appId: 'wxd4ed5f6c863d26c3',
                                                            package: Data.package_info,
                                                        },
                                                        function (res) {
                                                            if (res.err_msg === 'requestMerchantTransfer:ok') {
                                                                KeepZX(that, that.$store.state.UserName, '支', that.NowOutZXn, '退币提现', function () {
                                                                    that.OpenMyZXAccount = true;
                                                                });
                                                            }
                                                            if (res.err_msg === 'requestMerchantTransfer:fail') alert('提现失败！')
                                                            if (res.err_msg === 'requestMerchantTransfer:cancel') myWXpost("WeixinTransfer2", {out_bill_no: aObj.out_bill_no}, function (Data) {
                                                                alert('您并未确认收款，而是点击了取消！！！')
                                                            })
                                                        });
                                                } else {
                                                    alert('你的微信版本过低，请更新至最新版本。');
                                                }
                                            }
                                        });
                                    });
                            });
                        }
                    });
            },
            FYBtoZX() {
                let that = this;
                myMongoDBPost("GetDocs/fywuser0", {UserName: that.fywUserName, Password: that.fywPassword, ZX: that.fywZXn}, function (Docs1) {
                    if (Docs1.length > 0) {
                        that.FromFYWed = true;
                        KeepZX(that, that.$store.state.UserName, '收', that.fywZXn, "从泛约网导入", function () {
                        });
                        that.CurrUserBools.push("泛约币已导入");
                        myMongoDBPost("UpdateDoc/fyuser0", {
                            UPTJ: {UserName: that.$store.state.UserName},
                            UPObj: {UserBools: that.CurrUserBools}
                        }, function () {
                        })
                    } else alert("导入失败，请检查以上信息是否正确！")
                })
            },
            FYBtoZXNoMore() {
                that.CurrUserBools.push("放弃导入泛约币");
                that.FromFYWed = true;
                myMongoDBPost("UpdateDoc/fyuser0", {
                    UPTJ: {UserName: that.$store.state.UserName},
                    UPObj: {UserBools: that.CurrUserBools}
                }, function () {
                })
            }
        },
    }
</script>

<style scoped>

</style>
