<template>
    <div>
        <div v-if="$store.state.UserName==='游客'" class="MP4 TC  BGWhite">
            <p>能用<span class="TE">尊享</span>解决的问题，都不是问题。</p>
            <p>只要注册为泛约用户，相信任何困难都会有人站出来帮助您解决和面对，您将永远告别孤单无助！</p>
        </div>
        <div v-else>
            <div v-if="Show===1">
                <br>
                <div class="XLR YC MLR4">
                    标题：
                    <div class="XL YC TC">
                        悬赏<input type="number" class="W12" v-model.number="CurrQYDoc.ZXn" @focus="CurrQYDoc.ZXn=null" style="height: 2rem"/>个尊享
                    </div>
                    <div class="AN5 AN52" @click="SaveBBs(CurrQYDoc.Title)"> 确认</div>
                </div>
            </div>
            <div :class="(Show===1 ? 'BGWhite':'BGBlack')+' AlignC'"><br>
                <input type="text" class="W90 BD_Radius1" v-model="CurrQYDoc.Title" placeholder="" @focus="Show=1,CurrQYDoc.Title=null"/>
            </div>
            <div v-if="Show===1" class="XLR YC PA3 BGWhite">
                <div class="XL YC">附加信息：
                    <ShowUpdatePVT :PVTF="{P:CurrQYDoc.Pic,V:CurrQYDoc.Video,T:CurrQYDoc.Text, TextTitle:'文字内容表述'}"
                                   @ShowUT="CallBack"></ShowUpdatePVT>
                </div>&nbsp;
            </div>
            <div v-if="Show===2">
                <el-divider>消息实时群发(收费)</el-divider>
                <div class="XL YC MP1">范围：
                    <FYArea @FromFYArea="GetMsg($event,1)"></FYArea>
                </div>
                <div class="XLR YC">
                    <div class="XL YC MP1">
                        性别：
                        <MySelectStr CallFrom="FYBB" :Msg="['不限','男','女']" :CurrN="0" @eventFromChild="GetMsg($event,2)"></MySelectStr>
                    </div>
                    <div class="XL YC MP1">
                        年龄：
                        <MySelectRange CallFrom="FYBB" :Msg="['不限',Age+'岁']" @eventFromChild="GetMsg($event,3)"></MySelectRange>
                    </div>
                </div>
                <div class="XLR PA2">
                    <div>需支出{{ZXn}}个尊享</div>
                    <div class="AN ANBlue" @click="SendWxMsg">发送</div>
                </div>
            </div>
            <div v-else class="AlignC BGBlack">
                <img src="../../../static/gif2/Gif1.gif" style="width: 95vw;height: auto"><br><br>
            </div>
        </div>
        <br>
        <FYBB_AllBBs v-if="Show===-1"></FYBB_AllBBs>
        <WXLink v-if="$store.state.UserName!=='游客'" WText="只要注册为泛约用户， 许多困难都会有人站出来帮助您解决和面对，您将永远告别孤单无助！"
                WTitle="能用尊享解决的问题，都不是问题。" WLandTo="FYBB" Pic="UserUpload/static/WXLink/FYBB.jpg"></WXLink>
    </div>
</template>

<script>
    import {myMongoDBPost, KeepZX, TemplateObj6, SendWXMsg} from '@/components/SharedVues/Shared0.js'
    import ShowUpdatePVT from "@/components/MyPVTs/ShowUpdatePVT";
    import PopTitle from "@/components/MyPopWin/PopTitle";
    import FYBB_AllBBs from "./FYBB_AllBBs";
    import WXLink from "../IndependentVues/WXLink";
    import FYArea from "../SharedVues/FYArea";
    import MySelectRange from "../SharedVues/MySelectRange";
    import MySelectStr from "../SharedVues/MySelectStr";

    //一方有难 八方支援 让天下人都没有过不去的坎儿!
    //要 帮 忙<br>尽 管 说 //一呼百应
    export default {
        name: "FYBB",
        components: {
            MySelectStr,
            MySelectRange,
            FYArea,
            WXLink,
            FYBB_AllBBs,
            PopTitle,
            ShowUpdatePVT
        },
        data() {
            return {
                Loaded: false,
                HaveBBs: false,
                CurrQYDoc: {
                    UserName: this.$store.state.UserName,
                    Certified: this.$store.state.Certified,
                    UserLogo: this.$store.state.UserLogo,

                    Channel: '泛约帮办',
                    Type: '综合',//泛约调度填写：行业划分，用于查找让谁解决问题
                    Key3: '综合',//泛约调度填写：问题进一步划分
                    Title: '✍任务、求助、需求等一句话标题',

                    Province: this.$store.state.FYArea.split(":")[1],
                    City: this.$store.state.FYArea.split(":")[2],

                    ZXn: 1,
                    Pic: '',
                    Video: '',
                    Text: '',
                    Time: (new Date()).getTime(),
                    Strength: '随缘',
                    EndBool: 1
                },
                OpenShow: false,
                InputI: -1,
                Show: -1,

                Sex: '不限',
                Age: '不限',
                ZXn: 0,
                SendUsers: []
            }
        },
        mounted() {
            let that = this
            myMongoDBPost("GetDocs/QYs", {Channel: '泛约帮办', UserName: this.$store.state.UserName, EndBool: 1}, function (Docs) {
                if (Docs.length > 0) that.HaveBBs = true;
                if (that.$store.state.LandTo && that.$store.state.LandTo === 'WNFB') that.InputI = 1;
                if (that.$store.state.LandTo && that.$store.state.LandTo === 'WRMFW') that.OpenBBs();
            })//如果当前用户已有未了结的任务，则显示FYBBing
        },
        methods: {
            CallBack(Val) {
                if (Val.P) this.CurrQYDoc.Pic = Val.P;
                if (Val.V) this.CurrQYDoc.Video = Val.V;
                if (Val.T) this.CurrQYDoc.Text = Val.T;
            },
            SaveBBs(Title) {
                let that = this;
                this.CurrQYDoc.Title = Title;
                if (Title.length < 3) alert('操作提示, 请您说得再详细些!');
                else myMongoDBPost("PutDoc/QYs", this.CurrQYDoc, function (data) {
                    that.CurrQYDoc._id = data._id;
                    that.Show = 2;
                });
            },
            GetMsg(Val, Bool) {
                let that = this;
                console.log(Val);
                //if (Bool === 1) console.log(this.$store.state.FYArea);
                if (Bool === 2) this.Sex = Val;
                if (Bool === 3) this.Age = Val;
                myMongoDBPost("ReadfyUsers", {AreaStr: this.$store.state.FYArea, Sex: this.Sex, Age: this.Age}, function (Docs) {
                    that.ZXn = Docs.length * 2;
                    that.SendUsers = Docs;
                });
            },
            SendWxMsg() {
                let that = this;
                let UrlSuffix = ' \n[ MyUrlHere ]MyUrlHereMyUrl=' + 'LandTo=FYBBSendMsgQWERQYId=' + this.CurrQYDoc._id + 'QWERCallFrom=BB___';
                if (that.$store.state.ZX < this.ZXn) alert('您的尊享数量不足：无法群发!');
                else if (this.SendUsers.length > 0)
                    KeepZX(this, this.$store.state.UserName, '支', this.ZXn, "泛约帮办群发信息", function () {
                        for (let i1 = 0, len1 = that.SendUsers.length; i1 < len1; i1++)  //遍历每一个用户
                            (function (i1) {
                                console.log(that.SendUsers[i1].UserName)
                                let X = that.CurrQYDoc._id, Y = that.CurrQYDoc.UserName, Z = that.CurrQYDoc.Title;
                                //if (['决不放弃', '紫丁香', 'renyan', '素野凝香', '雨点'].indexOf(that.SendUsers[i1].UserName) >= 0)
                                SendWXMsg(that.SendUsers[i1].UserName, "泛约帮办>" + that.CurrQYDoc._id + UrlSuffix, TemplateObj6(X, Y, Z));
                            })(i1);
                        alert('发送完毕!');
                    })
            }
        }
    }
</script>

<style scoped>

</style>
